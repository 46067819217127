/* Estilo para o container das categorias (stories) */
.categoriesContainer {
  display: flex;
  justify-content: flex-start;
  overflow-x: auto; /* Scroll lateral */
  padding: 10px 0;
  white-space: nowrap;
  position: fixed; /* Torna o container fixo no topo da página */
  top: 0; /* Fixa no topo da página */
  left: 0; /* Alinha à esquerda */
  width: 100%; /* Ocupa 100% da largura da tela */
  background-color: white; /* Fundo branco para que as categorias não sobreponham o conteúdo */
  z-index: 1000; /* Garante que o container fique acima do conteúdo */
  border-bottom: 1px solid #ddd; /* Linha separadora para as categorias */
  scrollbar-width: none; /* Esconde a barra de rolagem no Firefox */
  -ms-overflow-style: none; /* Esconde a barra de rolagem no IE/Edge */
}

/* Esconde a barra de rolagem no Chrome/Safari */
.categoriesContainer::-webkit-scrollbar {
  display: none;
}

/* Estilo dos círculos das categorias */
.story img {
  width: 90px; /* Aumenta o tamanho do círculo */
  height: 90px; /* Aumenta o tamanho do círculo */
  border-radius: 50%; /* Garante que a imagem fique circular */
  object-fit: cover; /* Mantém a proporção da imagem */
  border: 3px solid #d6249f; /* Borda para destacar os círculos */
  margin: 0 10px; /* Espaçamento lateral entre os círculos */
}

/* Para ajustar o conteúdo abaixo das categorias congeladas */
.content-container {
  margin-top: 1px; /* Ajusta o espaço para evitar que o conteúdo fique atrás das categorias */
}

/* Estilo para o container do feed */
.feed-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px; /* Reduzindo o padding para aproximar o feed das categorias */
}

/* Estilo dos posts no feed */
.post {
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: white;
}

/* Estilo da mídia (imagem ou vídeo) no post */
.feed-media {
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover; /* Faz a imagem preencher o espaço sem distorcer */
}

/* Seção de curtidas */
.like-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

/* Botão de curtir */
.like-button {
  background: none;
  border: none;
  color: red;
  font-size: 18px;
  cursor: pointer;
}

.like-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
