header {
    padding: 10px;
    background-color: #fff;
  }
  
  nav {
    display: flex;
    justify-content: space-between;
  }
  
  nav a {
    text-decoration: none;
    color: black;
    margin: 0 10px;
  }
  