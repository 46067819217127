.categories-container {
  display: flex;
  justify-content: flex-start;
  overflow-x: scroll;
  padding: 10px 0;
  white-space: nowrap;
  scrollbar-width: none;
}

.category-story {
  display: inline-block;
  margin: 0 10px;
  text-align: center;
  cursor: pointer;
}

.category-story img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #d6249f;
}

.category-story p {
  color: black; /* Define a cor do texto para preto */
  text-decoration: none; /* Remove o sublinhado do texto */
  font-size: 14px;
  margin-top: 5px;
}
