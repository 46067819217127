.feed-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .post-container {
    position: relative;
    margin: 20px;
    width: 300px;
    overflow: hidden; /* Garante que o conteúdo não ultrapasse os limites */
    border-radius: 8px; /* Aplica o arredondamento para imagem e descrição */
  }
  
  .post-image {
    width: 100%;
    border-radius: 8px; /* Bordas arredondadas */
    display: block;
  }
  
  .post-description {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.5); /* Fundo semitransparente (fumê) */
    color: white;
    padding: 10px;
    text-align: center;
    font-size: 14px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-sizing: border-box; /* Garante que o padding seja contido */
  }
  